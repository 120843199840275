import {useEffect, useState} from 'react'
import {validateOrderInHomePgae} from 'src/utils/homePage'

export default function useOrderExistInHomePage(taskName: string) {
  const [exist, setExist] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!taskName) {
      setExist(false)
      return
    }

    ;(async () => {
      setIsLoading(true)
      setExist(await validateOrderInHomePgae(taskName))
      setIsLoading(false)
    })()
  }, [taskName])

  return {exist, isLoading}
}
