const config = {
  isDevelopment: process.env.NODE_ENV === 'development',
  isProduction: process.env.REACT_APP_BRANCH_NAME === 'main',
  logRocketAppId: process.env.REACT_APP_LOGROCKET_APPID || '',
  rollbarPostClientItem: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM || '',
  getBoardEmailsUrl: process.env.REACT_APP_GET_BOARDEMAILS_URL || '',
  sendBoardEmailUrl: process.env.REACT_APP_SEND_BOARDEMAIL_URL || '',
  getFormsUrl: process.env.REACT_APP_GET_FORMS_URL || '',
  sendMondayLogsUrl: process.env.REACT_APP_SEND_MONDAY_LOGS_URL || '',
  getDraftsUrl: process.env.REACT_APP_GET_DRAFTS_URL || '',
  getCorrespondencesUrl: process.env.REACT_APP_GET_CORRESPONDENCES_URL || '',
  sendCorrespondenceUrl: process.env.REACT_APP_SEND_CORRESPONDENCE_URL || '',
  getSignalRConnectionInfoUrl:
    process.env.REACT_APP_GET_SIGNALR_CONNECTION_INFO_URL || '',
  addDraftUrl: process.env.REACT_APP_ADD_DRAFT_URL || '',
  updateDraftUrl: process.env.REACT_APP_UPDATE_DRAFT_URL || '',
  updateEmailsParentIdUrl:
    process.env.REACT_APP_UPDATE_EMAILS_PARENT_ID_URL || '',
  updateEmailsFileNumber:
    process.env.REACT_APP_UPDATE_EMAILS_FILE_NUMBER_URL || '',
  updateHandled: process.env.REACT_APP_UPDATE_HANDLED_URL || '',
  getAttachmentsByParentId:
    process.env.REACT_APP_GET_ATTACHMENTS_BY_PARENT_ID_URL || '',
  deleteDraftUrl: process.env.REACT_APP_DELETE_DRAFT_URL || '',
  deleteDraftsUrl: process.env.REACT_APP_DELETE_DRAFTS_URL || '',
  generateSasUrl: process.env.REACT_APP_GENERATE_SAS_URL || '',
  uploadFileToHomePageUrl: process.env.REACT_APP_UPLOAD_FILE_URL || '',
  uploadTextToHomePageUrl: process.env.REACT_APP_UPLOAD_TEXT_URL || '',
  UploadEmailWithAttachmentsToHomepage:
    process.env.REACT_APP_UPLOAD_EMAIL_WITH_ATTACHMENTS_URL || '',
  UploadEmailWithFilesAttachmentsToHomepage:
    process.env.REACT_APP_UPLOAD_EMAIL_WITH_FILES_ATTACHMENTS_URL || '',
  uploadBlobToHomePageUrl: process.env.REACT_APP_UPLOAD_BLOB_URL || '',
  categoriesUrl: process.env.REACT_APP_GET_CATEGORIES_URL || '',
  documentsUrl: process.env.REACT_APP_GET_DOCUMENTS_URL || '',
  documentsSizeUrl: process.env.REACT_APP_GET_DOCUMENTS_SIZE_URL || '',
  readAssetsFromHPUrl: process.env.REACT_APP_HP_READ_ASSETS_URL || '',
  uploadAssetsToMondayUrl: process.env.REACT_APP_MONDAY_UPLOAD_ASSETS || '',
  readAssets: process.env.REACT_APP_MONDAY_READ_ASSETS || '',
  updateIsRead: process.env.REACT_APP_UPDATE_IS_READ_URL || '',
  mondayApiUrl: process.env.REACT_APP_MONDAY_API_URL || '',
  mondayApiToken: process.env.REACT_APP_MONDAY_API_TOKEN || '',
  copyUpdates: process.env.REACT_APP_COPY_UPDATES || '',
  dropboxClientId: process.env.REACT_APP_DROPBOX_CLIENT_ID || '',
  dropboxClientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET || '',
  dropboxRedirectUri: process.env.REACT_APP_DROPBOX_REDIRECT_URI || '',
  azureBlobStorage: {
    storageName: process.env.REACT_APP_STORAGE_NAME || '',
    containerName: process.env.REACT_APP_CONTAINER_NAME || ''
  },
  contactsBoard: process.env.REACT_APP_CONTACTS_BOARD || '',
  difSign: 25513472,
  mailBodyUrl: process.env.REACT_APP_MAIL_BODY_URL || '',
  sendMailUrl: process.env.REACT_APP_SEND_MAIL_URL || '',
  fixFileNumberUrl: process.env.REACT_APP_FIX_FILE_NUMBER_URL || '',
  moveOrCopyUrl: process.env.REACT_APP_MOVE_COPY_URL || '',
  moveOrCopyCorrespondenceUrl:
    process.env.REACT_APP_MOVE_COPY_CORRESPONDENCE_URL || '',
  validateOrderInHomePgaeUrl:
    process.env.REACT_APP_VALIDATE_ORDER_LINE_URL || '',
  archiveItemUrl: process.env.REACT_APP_ARCHIVE_ITEM || '',
  azureSearchUrl: process.env.REACT_APP_AZURE_SEARCH || '',
  mondayBaseUrl: process.env.REACT_APP_MONDAY_BASE_URL || '',
  getAllBoardsUrl: process.env.REACT_APP_GET_ALL_BOARDS || '',
  submitDetailsFormUrl: process.env.REACT_APP_SUBMIT_DETAILS_FORM_URL || ''
}

export default config
