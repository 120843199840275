import React, {useCallback, useContext} from 'react'
import {useSearchParams} from 'react-router-dom'
import User from 'src/entities/User'
import useSubscribers from 'src/hooks/api/useSubscribers'
import useContacts from 'src/hooks/api/useContacts'
import useMe from 'src/hooks/api/useMe'

export const findUserBy = (
  findBy: 'email' | 'name',
  value: string,
  users?: User[]
) => {
  value = value.toLowerCase()
  return users?.find(
    u =>
      u[findBy]?.toLowerCase().replace(/\s+/g, ' ') ===
      value.replace(/\s+/g, ' ')
  )
}

interface UserContextValue {
  contacts?: User[]
  isLoadingContacts: boolean
  subscribers?: User[]
  me?: User
  getPhotoSrc: (email?: string, name?: string) => string | undefined
}

const UsersContext = React.createContext({} as UserContextValue)

function UsersContextProvider({children}: any) {
  const [searchParams] = useSearchParams()
  const boardId = searchParams.get('boardId') || ''
  const {me} = useMe()
  const {subscribers} = useSubscribers(boardId)
  const {contacts, isLoading} = useContacts()

  const getPhotoSrc = useCallback(
    (email?: string, name?: string) => {
      if (!email && name) {
        email = findUserBy('name', name, contacts)?.email
      }
      return email ? findUserBy('email', email, subscribers)?.photo : undefined
    },
    [contacts, subscribers]
  )

  const value = {
    contacts,
    isLoadingContacts: isLoading,
    subscribers,
    me: me,
    getPhotoSrc
  }

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
}

const useUsersContext = () => useContext(UsersContext)

export {UsersContextProvider, useUsersContext}
