import React, {useContext} from 'react'
import {useLocation, useSearchParams} from 'react-router-dom'
import HPDocumentCategory from 'src/entities/HPDocument'
import STWCategory from 'src/entities/STWCaterory'
import useFileName from 'src/hooks/api/useFileName'
import useHPDocuments from 'src/hooks/api/useHPDocuments'
import useOrderExistInHomePage from 'src/hooks/api/useOrderExistInHomePage'
import useSTWCategories from 'src/hooks/api/useSTWCategories'

interface SaveToWebContextValue {
  fileName: string
  setFileName: (newName: string) => void
  exist?: boolean
  categories: STWCategory[]
  isLoadingCategories: boolean
  documents: HPDocumentCategory[]
  isLoadingDocuments: boolean
}

const SaveToWebContext = React.createContext({} as SaveToWebContextValue)

function SaveToWebContextProvider({children}: any) {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const fileId =
    searchParams.get('parentId') ||
    ((location.pathname.includes('bulk') ||
      location.pathname.includes('correspondence') ||
      location.pathname.includes('documents') ||
      location.pathname.includes('aak')) &&
      searchParams.get('itemId')) ||
    undefined
  const emailId = !fileId ? searchParams.get('itemId') || undefined : undefined
  const {fileName, setFileName} = useFileName(fileId, emailId) //'108000-Test'''
  const {categories, isLoading} = useSTWCategories(fileName)
  const {documents, isLoadingDocuments} = useHPDocuments(fileName)
  const {exist, isLoading: isLoadingExist} = useOrderExistInHomePage(fileName)
  const value = {
    fileName: fileName,
    setFileName,
    exist: exist,
    categories: categories,
    isLoadingCategories: isLoading,
    documents: documents,
    isLoadingDocuments: isLoadingDocuments,
    isLoadingExist: isLoadingExist
  }

  return (
    <SaveToWebContext.Provider value={value}>
      {children}
    </SaveToWebContext.Provider>
  )
}

const useSaveToWebContext = () => useContext(SaveToWebContext)

export {SaveToWebContextProvider, useSaveToWebContext}
