import React, {useEffect, useRef, useState} from 'react'
import config from 'src/config'
import {useUsersContext} from 'src/context/Users'

const ReportersContext = React.createContext({})

function ReportersContextProvider({children}: any) {
  const reportersRef = useRef<any>(null)
  const [ready, setReady] = useState(false)
  const {me} = useUsersContext()

  useEffect(() => {
    if (config.isDevelopment) return // dont run in dev

    import(/* webpackChunkName: 'reporters' */ './../utils/reporters').then(
      x => {
        reportersRef.current = x.default
        setReady(true)
      }
    )
  }, [])

  // update reporters with user data
  useEffect(() => {
    if (!ready || !me || !Object.keys(me).length) return

    const {logrocket, rollbar} = reportersRef.current

    const {name, email} = me

    logrocket.identify(me.id, {name, email})
    rollbar.configure({
      payload: {person: {id: me.id, email, username: name}}
    })
  }, [ready, me])

  return (
    <ReportersContext.Provider value={{}}>{children}</ReportersContext.Provider>
  )
}

export {ReportersContextProvider, ReportersContext}
