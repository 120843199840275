import {useEffect, useState} from 'react'
import mondaySdk from 'monday-sdk-js'
import config from 'src/config'
import User, {validateEmail} from 'src/entities/User'
import {MonItem, MonRes} from 'src/entities/Monday'
import {getBoardNextItemsPage} from 'src/utils/monday'

const monday = mondaySdk()
monday.setToken(config.mondayApiToken)
monday.setApiVersion('2023-10')

const PAGE_LIMIT = 500

const contactsQuery = () => `query{
  boards (ids: ${config.contactsBoard}) {
    items_page(limit:${PAGE_LIMIT}) {
      cursor
      items {
        id
        name
      }
    }
  }
}`

const fillContacts = (items: MonItem[]) => {
  const contacts = [] as User[]
  for (let i = 0; i < items.length; i++) {
    const monContact = items[i]
    const contact = {} as User
    contact.id = Number(monContact.id)
    const data = monContact.name?.split(';')
    contact.email = data && data[1] ? data[1] : ''
    contact.name = data ? data[0] : ''
    contacts.push(contact)
  }
  return contacts
}

async function* getContacts() {
  const contacts = [] as User[]
  try {
    if (config.sendMondayLogsUrl.length > 0)
      await fetch(
        `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
          'function:getContacts-contactsQuery'
        )}`
      )
    const res = (await monday.api(contactsQuery())) as MonRes
    if (
      res &&
      res.data.boards &&
      res.data.boards.length &&
      res.data.boards[0].items_page
    ) {
      const items = res.data.boards[0].items_page.items
      if (items) contacts.push(...fillContacts(items))
      let cursor = res.data.boards[0].items_page.cursor
      while (cursor) {
        yield contacts
        const res = await getBoardNextItemsPage(cursor, PAGE_LIMIT)
        if (
          res &&
          res.data.next_items_page &&
          res.data.next_items_page.items &&
          res.data.next_items_page.items.length
        ) {
          contacts.push(...fillContacts(res.data.next_items_page.items))
          cursor = res.data.next_items_page.cursor
        }
      }
    }
  } catch {}
  return contacts
}

export default function useFetchContact() {
  const [contacts, setContacts] = useState<User[]>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      console.log(`useFetchContact `)
      setIsLoading(true)
      let iterator = await getContacts()
      let result: IteratorResult<User[], User[]> | null = null
      do {
        result = await iterator.next()
        const newValue = result.value.filter(c => validateEmail(c.email))
        setContacts(prev =>
          prev ? prev.concat(newValue.slice(prev?.length)) : newValue
        )
      } while (!result.done)
      setIsLoading(false)
    })()
  }, [])

  return {contacts, isLoading}
}
