export function replaceImages(htmlValue: string): [string, File[]] {
  const images: File[] = []
  const newHtml = htmlValue.replaceAll(
    /<img[^<]*src=["']([^<]*?)["'][^<]*>/g,
    (a, b) => {
      if (b.startsWith('data:image/')) {
        const image = dataUrlToFile(b)
        images.push(image)
        return a.replace('src="' + b + '"', 'src="cid:' + image.name + '"')
      }
      return a
    }
  )
  return [newHtml, images]
}

const dataUrlToFile = (dataurl: string) => {
  const arr = dataurl.split(',')
  const mime = arr[0]?.match(/:(.*?);/)?.[1]
  const fileName =
    crypto.randomUUID() + (mime?.indexOf('png') !== -1 ? '.png' : '.jpeg')

  return new File([Buffer.from(arr[1], 'base64')], fileName, {type: mime})
}

export function removeSendGridImage(htmlValue: string) {
  return htmlValue.replaceAll(/<img[^<]*src=["']([^<]*?)["'][^<]*>/g, (a, b) =>
    b.startsWith('http://url9578.lprd.com') ||
    b.startsWith('https://u27863536.ct.sendgrid.net')
      ? ''
      : a
  )
}
