import config from 'src/config'
import {Attachment} from 'src/entities/Email'

export const updateHandled = async (
  emailIds: number[],
  isHandled: boolean,
  boardId: string,
  itemId: string
) => {
  const formdata = new FormData()
  formdata.append(
    'Body',
    JSON.stringify({emailIds, isHandled, boardId, itemId})
  )
  var requestOptions = {
    method: 'PUT',
    body: formdata
  }
  try {
    const res = await fetch(config.updateHandled, requestOptions)
    return {isSucceeded: res.ok}
  } catch {
    return {isSucceeded: false}
  }
}
export const updateMailIsRead = async (emailId: number, isRead: boolean) => {
  const formdata = new FormData()
  formdata.append('Body', JSON.stringify({emailId, isRead}))
  var requestOptions = {
    method: 'PUT',
    body: formdata
  }
  try {
    const res = await fetch(config.updateIsRead, requestOptions)
    return {isSucceeded: res.ok}
  } catch {
    return {isSucceeded: false}
  }
}

export const updateEmailsParentId = async (
  oldParentId: string,
  newParentId: string,
  boardId: string,
  fileNumber: string,
  isMoveBetweenBoards: boolean
) => {
  const formdata = new FormData()
  formdata.append(
    'Body',
    JSON.stringify({
      oldParentId,
      newParentId,
      boardId,
      fileNumber,
      isMoveBetweenBoards
    })
  )
  var requestOptions = {
    method: 'PUT',
    body: formdata
  }
  try {
    const res = await fetch(config.updateEmailsParentIdUrl, requestOptions)
    return {isSucceeded: res.ok}
  } catch {
    return {isSucceeded: false}
  }
}

export const updateEmailsFileNumber = async (
  parentId: string,
  fileNumber: string,
  lastBoardId: string,
  destBoardId: string,
  userId: number
) => {
  const formdata = new FormData()
  formdata.append(
    'Body',
    JSON.stringify({parentId, fileNumber, lastBoardId, destBoardId, userId})
  )
  var requestOptions = {
    method: 'PUT',
    body: formdata
  }
  try {
    const res = await fetch(config.updateEmailsFileNumber, requestOptions)
    const data = lastBoardId
      ? ((await res.json()) as string)
      : ((await res.json()) as boolean)
    return data
  } catch {
    return {isSucceeded: false}
  }
}

export const getAttachmentsByParentId = async (parentId: string) => {
  try {
    const res = await fetch(`${config.getAttachmentsByParentId}&id=${parentId}`)
    const data = (await res.json()) as Attachment[]
    return data
  } catch {
    return []
  }
}
export const archiveItem = async (itemId: string) => {
  const formdata = new FormData()
  formdata.append('Body', JSON.stringify({itemId}))
  var requestOptions = {
    method: 'PUT',
    body: formdata
  }
  try {
    const res = await fetch(config.archiveItemUrl, requestOptions)
    return {isSucceeded: res.ok}
  } catch {
    return {isSucceeded: false}
  }
}
