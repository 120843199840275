import File from 'src/entities/File'
import User from 'src/entities/User'

export default interface Subitem {
  id: string
  parent: File
  name: string
  subject: string
  from: string
  to: string
  cc: string
  date: Date
  content: string
  assignee: User
  handled: boolean
  docs: Doc[]
  attachedEmails: Doc[]
  sourceFolder: string
  columnValues: ColumnValues[]
  html: string
  sentViaMonday: boolean
  isDraft?: boolean
  emailCategory: EmailCategory
  fn: string
  isPriority: boolean
  invoice?: Doc[]
}

export interface Email {
  id: number
  parentId: string
  boardId: string
  subject: string
  from: string
  to: string
  cc: string
  updatedAt: Date
  body: string
  emailType: EmailType
  attachments: Attachment[]
  fileNumber?: string
  messageId?: string
  conversationId?: string
  isHandled?: boolean
  emailStatus: EmailStatus
  emailCategory: EmailCategory
  isPriority?: boolean
  isRead?: boolean
  isSentViaSystem?: boolean
}

export interface Attachment {
  id: number
  emailId: number
  fileId?: string
  name: string
  size: number
  publicUrl?: string
  isFromHomePage?: boolean
  isBlob?: boolean
  convertStatus?: ConvertStatus
  isOrigin?: boolean
}

interface ColumnValues {
  id: string
  title: string
  value: string
  text: string
}

export interface Doc {
  fileType: string
  assetId: string
  name: string
  isImage: string
}

export interface MocOrder {
  id: string
  fileNumber: string
}

export enum EmailType {
  New = 1,
  Forward,
  Reply
}

export enum EmailStatus {
  Draft = 1,
  Sent
}

export enum EmailCategory {
  Email = 1,
  Correspondence,
  BoardEmail
}

export enum ConvertStatus {
  Success = 1,
  Failed,
  Empty
}

export enum ActionType {
  Send = 'Send',
  Move = 'Move',
  Copy = 'Copy',
  MoveByFix = 'Move by fix'
}
