import React from 'react'
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Theme,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
interface MonLoaderProps {
  message?: string
  backdrop?: boolean
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit'
  size?: number | string
  ml?: number | string
  mt?: number | string
  onClose?: React.MouseEventHandler<HTMLButtonElement>
}

export default function MonLoader({
  message,
  backdrop,
  color,
  size,
  ml,
  mt,
  onClose
}: MonLoaderProps) {
  if (!backdrop)
    return (
      <>
        <CircularProgress
          sx={{ml: ml || 7.25, mt: mt || 3.5}}
          size={size || 30}
          color={color}
        />
        {message && (
          <Typography mt={1} variant="subtitle1" component="pre">
            {message}
          </Typography>
        )}
      </>
    )

  return (
    <Backdrop
      sx={{
        backgroundColor: '#00000080',
        color: 'common.white',
        zIndex: (theme: Theme) => theme.zIndex.modal + 1
      }}
      open
    >
      {onClose && (
        <IconButton
          onClick={onClose}
          color="inherit"
          sx={{
            position: 'absolute',
            top: 2,
            right: 2
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box textAlign="center">
        <CircularProgress size={48} color="inherit" />
        {message && (
          <Typography mt={1} variant="subtitle1" component="pre">
            {message}
          </Typography>
        )}
      </Box>
    </Backdrop>
  )
}
