import {useEffect, useState} from 'react'
import mondaySdk from 'monday-sdk-js'
import User from 'src/entities/User'
import {MonRes} from 'src/entities/Monday'
import config from 'src/config'

const monday = mondaySdk()
monday.setApiVersion('2023-10')

const meQuery = () => `query{
  me {
    name
    id
    photo_small
    email
  }
}`

const getMe = async () => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        'function:getMe-meQuery'
      )}`
    )
  const res = (await monday.api(meQuery())) as MonRes
  if (res && res.data && res.data.me) {
    return {
      id: Number(res.data.me.id),
      name: res.data.me.name,
      displayName: res.data.me.name + ' (You)',
      email: res.data.me.email,
      photo: res.data.me.photo_small
    } as User
  }
}

const getInitializeState = () => {
  const me = localStorage.getItem('me')
  return me ? (JSON.parse(me) as User) : undefined
}

export default function useMe() {
  const [me, setMe] = useState<User | undefined>(getInitializeState)

  useEffect(() => {
    ;(async () => {
      const meUser = await getMe()
      if (meUser) {
        setMe(meUser)
        localStorage.setItem('me', JSON.stringify(meUser))
      }
    })()
  }, [])

  return {me}
}
