import React from "react";
import { responsiveFontSizes } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from "@mui/styles";

const Styles = ({ children }) => {
    const theme = responsiveFontSizes(
        createTheme({
            palette: {
                primary: {
                    main: "#0073ea"
                },
                secondary: {
                    main: "#00CA72",
                    light: "#CCF4E3"
                },
                error: {
                    main: '#E44258'
                },
                text: {
                    primary: "#323338",
                    secondary: "#676879",
                    disabled: "#C5C7D0",
                },
                common: {
                    black: "#323338"
                },
                border: {
                    main: "#C5C7D0"
                },
                grey: {
                    common: '#E6E9EF'
                },
                selected: {
                    primary: '#DFF0FF'
                }
            },
            typography: {
                body1: { fontSize: 14 },
                h6: { fontSize: 18 },
                subtitle1:{fontWeight: 500},
                button: {
                    fontSize: 14,
                    textTransform: 'none'
                }
            },
        })
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <StylesProvider>{children}</StylesProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default Styles;
