export default interface User {
  id: number
  name: string
  displayName: string
  email: string
  photo?: string
}

export interface Contact {
  id: string
  name: string
  email: string
  filledForm?: boolean
  letterReminder?: boolean
}

export const validateEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
