import {useEffect, useState} from 'react'
import config from 'src/config'
import STWCategory from 'src/entities/STWCaterory'

const getCategories = (taskName: string) => {
  return new Promise<STWCategory[]>(resolve => {
    fetch(`${config.categoriesUrl}&orderNumber=${taskName}`)
      .then(response => response.json())
      .then(data => {
        resolve(data as STWCategory[])
      })
      .catch(err => {
        console.log(err)
        resolve([])
      })
  })
}

export default function useSTWCategories(taskName: string) {
  const [categories, setCategories] = useState<STWCategory[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    ;(async () => {
      setCategories(await getCategories(taskName))
      setIsLoading(false)
    })()
  }, [taskName])

  return {categories, isLoading}
}
