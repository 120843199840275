import {useEffect, useState} from 'react'
import config from 'src/config'
import HPDocuments from 'src/entities/HPDocument'

const getDocuments = (taskName: string) => {
  return new Promise<HPDocuments[]>(resolve => {
    fetch(`${config.documentsUrl}&orderNumber=${taskName}`)
      .then(response => response.json())
      .then(data => {
        resolve(data as HPDocuments[])
      })
      .catch(err => {
        resolve([])
      })
  })
}

export default function useHPDocuments(taskName: string) {
  const [documents, setDocuments] = useState<HPDocuments[]>([])
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false)

  useEffect(() => {
    setIsLoadingDocuments(true)
    ;(async () => {
      setDocuments(await getDocuments(taskName))
      setIsLoadingDocuments(false)
    })()
  }, [taskName])

  return {documents, isLoadingDocuments}
}
