import {useEffect, useState} from 'react'
import {getNameByFileId, getNameByEmailId} from 'src/utils/monday'

export default function useFileName(fileId?: string, emailId?: string) {
  const [fileName, setFileName] = useState('')
  //  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      console.log(`useFileName,fileId ${fileId} emailId: ${emailId} `)
      //  setIsLoading(true)
      if (fileId) {
        setFileName(await getNameByFileId(fileId))
      } else if (emailId) {
        setFileName(await getNameByEmailId(emailId))
      }
      //  setIsLoading(false)
    })()
  }, [fileId, emailId])

  return {fileName, setFileName}
}
