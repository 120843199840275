import mondaySdk from 'monday-sdk-js'
import {
  Column,
  ColumnIds,
  Config,
  getConfigByParentBoard
} from 'src/components/MailBox/config'
import config from 'src/config'
import Asset from 'src/entities/Asset'
import Subitem, {ActionType} from 'src/entities/Email'
import MonFile from 'src/entities/File'
import {ColumnValue, MonAsset, MonItem, MonRes} from 'src/entities/Monday'
import User from 'src/entities/User'
import {
  archiveItem,
  updateEmailsFileNumber,
  updateEmailsParentId
} from 'src/utils/email'
import {removeSendGridImage} from 'src/utils/image'

const monday = mondaySdk()
monday.setApiVersion('2023-10')

const userToString = (user: User) => `${user.name}(${user.id})`
const actionToString = (
  actionType: ActionType,
  fromParent: string,
  user: User
) => `${actionType}(from item: ${fromParent}) by ${userToString(user)};`

const getEmailColumnValue = (email: Subitem, column: Column) => {
  const columnValue = email.columnValues.find(c => c.id === column.id)
  return column.readValue ? columnValue?.value : columnValue?.text ?? ''
}

const strip = (html: string) => {
  return html.replace(/<(?:br|\/div|\/p)>/g, '\n').replace(/<.*?>/g, '')
}

const fixFileNumber = async (body: any) => {
  try {
    const res = await fetch(config.fixFileNumberUrl, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return res.ok
  } catch {
    return false
  }
}

const extractAssetId = (protectedUrl: string) => {
  const arr = protectedUrl.split('/')
  const assetId = arr[arr.findIndex(i => i === 'resources') + 1]
  return assetId
}

const docsColumnsQuery = (itemIds: string[], columnIds: string[]) => `query {
  items(ids:[${itemIds.join(',')}]) {
    column_values(ids:["${columnIds.join('","')}"]) {
      id
      value
      text
      ... on MirrorValue
      {
        display_value
      }
      ... on BoardRelationValue
      {
        display_value
      }
    }
  }
}`

export const getFileDocs = async (itemIds: string[], columnIds: string[]) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        'function:getFileDocs-docsColumnsQuery'
      )}`
    )
  const res = (await monday.api(docsColumnsQuery(itemIds, columnIds))) as MonRes
  if (
    res &&
    res.data.items &&
    res.data.items.length &&
    res.data.items[0].column_values
  ) {
    const documents = res.data.items[0].column_values
      .map((c: ColumnValue) =>
        c.display_value ? c.display_value.trim() : c.text?.trim() ?? ''
      )
      .join(',')
    const docsArr = documents?.length
      ? documents
          .split(',')
          .map((d: string) => extractAssetId(decodeURI(d.trim())))
      : []
    return docsArr
  }
  return []
}

const fileNameByEmailQuery = (emailId: string) => `query {
  items(ids:[${emailId}]) {
    parent_item{
      id
      name
    }
  }
}`

export const getNameByEmailId = async (emailId: string) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getNameByEmailId-fileNameByEmailQuery,email id:${emailId}`
      )}`
    )
  const res = (await monday.api(fileNameByEmailQuery(emailId))) as MonRes
  if (
    res &&
    res.data.items &&
    res.data.items.length &&
    res.data.items[0].parent_item
  ) {
    return res.data.items[0].parent_item.name ?? ''
  }
  return ''
}

const fileNameQuery = (fileId: string) => `query {
  items(ids:[${fileId}]) {
    id
    name
  }
}`
const columnQuery = (fileId: string, columnId: string) => `query {
  items (ids: [${fileId}]) {
      name
  column_values(ids:["${columnId}"]) {
    id
    text
    ... on BoardRelationValue
    {
      display_value
    }
    ... on MirrorValue 
    {
      display_value
    }
  }
  }
}`
export const getColumnData = async (fileId: string, columnId: string) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getColumnData-columnQuery,file id:${fileId}, column id: ${columnId}`
      )}`
    )
  const res = (await monday.api(columnQuery(fileId, columnId))) as MonRes
  if (
    res &&
    res.data.items &&
    res.data.items.length &&
    res.data.items[0].column_values &&
    res.data.items[0].column_values.length
  ) {
    return res.data.items[0].column_values[0].text ?? ''
  }
  return ''
}
export const getNameByFileId = async (fileId: string) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getNameByFileId-fileNameQuery,file id:${fileId}`
      )}`
    )
  const res = (await monday.api(fileNameQuery(fileId))) as MonRes
  if (res && res.data.items && res.data.items.length) {
    return res.data.items[0].name ?? ''
  }

  return ''
}

const filesQuery = (
  fileId: number[],
  columnIds: string[],
  dataType: string = 'value'
) => `query {
  items(ids:[${fileId}]) {
    id
    name
    column_values(ids:["${columnIds.join('","')}"]){
      id
      ${dataType}
      ... on BoardRelationValue
      {
        display_value
      }
      ... on MirrorValue 
      {
        display_value
      }
    }
  }
}`

const fileQuery = (
  fileId: string,
  columnIds: string[],
  dataType: string = 'value'
) => `query {
  items(ids:[${fileId}]) {
    id
    name
    column_values(ids:["${columnIds.join('","')}"]){
      id
      ${dataType}
      ... on BoardRelationValue
      {
        display_value
      }
      ... on MirrorValue 
      {
        display_value
      }
    }
  }
}`

const nextItemsPageQuery = (
  cursor: string,
  limit: number,
  columnIds: string[]
) =>
  `query {
  next_items_page(cursor:"${cursor}",limit:${limit}) {
   cursor
    items{
      column_values (ids:["${columnIds.join('","')}"]) {
        value
        text
        id
        ... on MirrorValue {
          display_value
        }
        ... on BoardRelationValue {
          display_value
        }
      }
    }
  }
}`

const boardNextItemsPageQuery = (cursor: string, limit: number) =>
  `query {
      next_items_page(cursor:"${cursor}", limit:${limit}) {
       cursor
        items{
          id
          name
        }
      }
    }`

export const getNextItemsPage = async (
  cursor: string,
  limit: number,
  columnId: string | string[]
) => {
  const columnIds = Array.isArray(columnId) ? columnId : [columnId]
  return (await monday.api(nextItemsPageQuery(cursor, limit, columnIds), {
    token: config.mondayApiToken
  })) as MonRes
}

export const getBoardNextItemsPage = async (cursor: string, limit: number) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        'function:getBoardNextItemsPage-boardNextItemsPageQuery'
      )}`
    )
  return (await monday.api(boardNextItemsPageQuery(cursor, limit), {
    token: config.mondayApiToken
  })) as MonRes
}

export const getColumnsData = async (
  itemId: string,
  columnId: string | string[]
) => {
  const columnIds = Array.isArray(columnId) ? columnId : [columnId]
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getColumnsData-fileQuery,item id:${itemId}, column id: ${columnId}`
      )}`
    )
  const res = (await monday.api(fileQuery(itemId, columnIds, 'text'), {
    token: config.mondayApiToken
  })) as MonRes
  if (res && res.data.items && res.data.items.length) {
    return [res.data.items.at(0)?.column_values, res.data.items.at(0)?.name]
  }
}

export const getColumnsDataOfMultipleItems = async (
  itemsIds: number[],
  columnId: string | string[]
) => {
  const columnIds = Array.isArray(columnId) ? columnId : [columnId]
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        'function:getColumnsDataOfMultipleItems-filesQuery'
      )}`
    )
  const res = (await monday.api(filesQuery(itemsIds, columnIds, 'text'), {
    token: config.mondayApiToken
  })) as MonRes
  if (res && res.data.items) {
    return res.data.items as MonItem[]
  }
}

export const getFile = async (
  fileId: string,
  columnId: string | string[],
  isAAK: boolean = false
) => {
  const columnIds = Array.isArray(columnId) ? columnId : [columnId]
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getFile-fileQuery,file id:${fileId}`
      )}`
    )
  const res = (await monday.api(fileQuery(fileId, columnIds, 'text'), {
    token: config.mondayApiToken
  })) as MonRes
  if (res && res.data.items && res.data.items.length) {
    const file = res.data.items[0]
    return {
      id: file.id,
      name: file.name,
      defaultSubject: isAAK
        ? file.column_values?.reduce(
            (a: string, b: any) =>
              `${a}${
                b.display_value
                  ? ` - ${b.display_value}`
                  : b.text
                  ? ` - ${b.text}`
                  : ''
              }`,
            ''
          )
        : file.column_values
        ? file.column_values[0].text ?? ''
        : ''
    } as MonFile
  }
}

export const getConnectedItem = async (fileId: string, columnId: string) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getConnectedItem-fileQuery,file id:${fileId}, column id: ${columnId}`
      )}`
    )
  const res = (await monday.api(fileQuery(fileId, [columnId]))) as MonRes
  if (
    res &&
    res.data.items &&
    res.data.items.length &&
    res.data.items[0].column_values &&
    res.data.items[0].column_values.length &&
    res.data.items[0].column_values[0].value
  ) {
    const value = JSON.parse(res.data.items[0].column_values[0].value)
    if (value && value.linkedPulseIds && value.linkedPulseIds.length)
      return (value.linkedPulseIds[0].linkedPulseId as string) || ''
  }

  return ''
}

export const sendNotification = async (
  text: string,
  userId: number,
  targetId: string
) => {
  try {
    if (config.sendMondayLogsUrl.length > 0)
      await fetch(
        `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
          `function:sendNotification,user id:${userId}`
        )}`
      )
    await monday.api(
      `mutation {
      create_notification(
        user_id: ${userId}, 
        target_id: ${targetId},
        text: "${text.replaceAll('\\', '\\\\').replaceAll('"', '\\"')}",
        target_type: Project) {
          text
        }}`,
      {token: config.mondayApiToken}
    )
  } catch {}
}

const copyAssets = async (
  itemId: string,
  columnId: string,
  assetsUrls: string[]
) => {
  try {
    const res = await fetch(config.uploadAssetsToMondayUrl, {
      method: 'POST',
      body: JSON.stringify({
        itemsId: [itemId],
        columnId: columnId,
        filesUrl: assetsUrls
      })
    })
    return !res.ok
  } catch {
    return false
  }
}

export const sendEmail = async (
  parentBoardId: string,
  parentId: string,
  columnIds: ColumnIds,
  subject: string,
  sender: User,
  me: User,
  to: string[],
  cc: string[],
  sourceFolder: string,
  body: string,
  bodyImages: File[],
  docs: Asset[],
  parentIdForFiles: string,
  parentFileColumnId: string,
  draftId: number | undefined,
  isPriority: boolean,
  sendToSender?: boolean,
  autoAssign?: boolean
) => {
  let columnValues: {[x: string]: any} = {
    [columnIds.from.id]: sender.name,
    [columnIds.dateReceived.id]: new Date()
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19),
    [columnIds.body.id]: strip(body.split('<hr')[0]).substring(0, 2000),
    [columnIds.to.id]: [me.email, ...to].join(';'),
    [columnIds.cc.id]: cc.join(';'),
    [columnIds.actionType.id]: actionToString(ActionType.Send, parentId, sender)
  }
  if (columnIds.sourceFolder)
    columnValues = {
      ...columnValues,
      [columnIds.sourceFolder.id]: sourceFolder
    }
  if (columnIds.subject)
    columnValues = {...columnValues, [columnIds.subject.id]: subject}
  if (columnIds.importance) {
    columnValues = {
      ...columnValues,
      [columnIds.importance.id]: isPriority ? {checked: 'true'} : null
    }
  }

  if (columnIds.sentViaMonday)
    columnValues = {
      ...columnValues,
      [columnIds.sentViaMonday.id]: {checked: 'true'}
    }
  if (
    columnIds.question &&
    [...to, ...cc].map(t => t.toLowerCase()).includes(me!.email)
  )
    columnValues = {
      ...columnValues,
      [columnIds.question.id]: {checked: 'true'}
    }
  else if (autoAssign)
    columnValues = {
      ...columnValues,
      [columnIds.assigned.id]: {personsAndTeams: [{id: me.id, kind: 'person'}]}
    }
  const columnValuesJson = JSON.stringify(columnValues)

  if (
    sendToSender &&
    !to.find(t => t.toLowerCase() === sender.email.toLowerCase()) &&
    !cc.find(t => t.toLowerCase() === sender.email.toLowerCase())
  ) {
    cc = [...cc, sender.email]
  }

  const bodyData = {
    UserId: me.id.toString(),
    boardId: parentBoardId,
    parentId,
    itemName: subject,
    ColumnValues: columnValuesJson,
    subject,
    sender,
    To: to,
    CC: cc,
    body,
    fileColumnId: columnIds.docs.id,
    parentIdForFiles,
    parentFileColumnId: parentFileColumnId,
    filesUrl: await getAssetsPublicUrls(
      docs.filter(d => !d.blob && !d.isFromHomePage).map(d => d.id)
    ),
    filesFromHomePage: docs
      .filter(d => d.isFromHomePage)
      .map(d => ({id: d.id, name: d.name})),
    userEmail: me.email,
    draftId,
    isPriority
  }

  const formdata = new FormData()

  for (let i = 0; i < docs.length; i++) {
    if (docs[i].blob) {
      formdata.append('Files', docs[i].blob!, docs[i].name)
    }
  }
  for (let i = 0; i < bodyImages.length; i++) {
    formdata.append('inline', bodyImages[i], bodyImages[i].name)
  }

  formdata.append('Body', JSON.stringify(bodyData))

  var requestOptions = {
    method: 'POST',
    body: formdata
  }
  try {
    const res = await fetch(config.sendMailUrl, requestOptions)
    return {isSucceeded: res.ok}
  } catch {
    return {isSucceeded: false}
  }
}

const subitemsQuery = (itemId: string, columnIds: string[] = []) => `query {  
  items (ids:[${itemId}]) {
    subitems {
      id
      column_values(ids:["${columnIds.join('","')}"]){
        id
        value
        text
      }
    }
  }
}`

const moveColumnsBetweenBoards = async (
  srcItemId: string,
  destItemId: string,
  lastFilesColumnId?: string,
  lastConfig?: Config
) => {
  if (lastFilesColumnId) {
    var lastLastFilesColumnId = lastConfig?.parentBoard.lastFiles?.id
    if (lastLastFilesColumnId) {
      const searchDocsAssets = await getFileDocs(
        [srcItemId],
        [lastLastFilesColumnId]
      )
      if (searchDocsAssets?.length) {
        await copyAssets(
          destItemId,
          lastFilesColumnId,
          await getAssetsPublicUrls(searchDocsAssets)
        )
      }
    }
  }
}
const moveDocsColumns = async (
  srcItemId: string,
  destItemId: string,
  searchDocsColumnId?: string,
  additionalInfoDocsColumnId?: string,
  continDocsColumnId?: string,
  proofedCommitmentDocsColumnId?: string,
  docsProofingColumnId?: string,
  lastFilesColumnId?: string,
  filesColumnId?: string
) => {
  if (searchDocsColumnId) {
    const searchDocsAssets = await getFileDocs(
      [srcItemId],
      [searchDocsColumnId]
    )
    if (searchDocsAssets?.length) {
      await copyAssets(
        destItemId,
        searchDocsColumnId,
        await getAssetsPublicUrls(searchDocsAssets)
      )
    }
  }
  if (lastFilesColumnId) {
    const searchDocsAssets = await getFileDocs([srcItemId], [lastFilesColumnId])
    if (searchDocsAssets?.length) {
      await copyAssets(
        destItemId,
        lastFilesColumnId,
        await getAssetsPublicUrls(searchDocsAssets)
      )
    }
  }
  if (filesColumnId) {
    const searchDocsAssets = await getFileDocs([srcItemId], [filesColumnId])
    if (searchDocsAssets?.length) {
      await copyAssets(
        destItemId,
        filesColumnId,
        await getAssetsPublicUrls(searchDocsAssets)
      )
    }
  }
  if (additionalInfoDocsColumnId) {
    const docsAssets = await getFileDocs(
      [srcItemId],
      [additionalInfoDocsColumnId]
    )
    if (docsAssets?.length) {
      await copyAssets(
        destItemId,
        additionalInfoDocsColumnId,
        await getAssetsPublicUrls(docsAssets)
      )
    }
  }
  if (continDocsColumnId) {
    const docsAssets = await getFileDocs([srcItemId], [continDocsColumnId])
    if (docsAssets?.length) {
      await copyAssets(
        destItemId,
        continDocsColumnId,
        await getAssetsPublicUrls(docsAssets)
      )
    }
  }
  if (proofedCommitmentDocsColumnId) {
    const docsAssets = await getFileDocs(
      [srcItemId],
      [proofedCommitmentDocsColumnId]
    )
    if (docsAssets?.length) {
      await copyAssets(
        destItemId,
        proofedCommitmentDocsColumnId,
        await getAssetsPublicUrls(docsAssets)
      )
    }
  }
  if (docsProofingColumnId) {
    const docsAssets = await getFileDocs([srcItemId], [docsProofingColumnId])
    if (docsAssets?.length) {
      await copyAssets(
        destItemId,
        docsProofingColumnId,
        await getAssetsPublicUrls(docsAssets)
      )
    }
  }
}
export const moveEmails = async (
  user: User,
  srcItemId: string,
  destItemId: string,
  fileNumber: string,
  lastBoardId: string,
  boardId: string,
  subitemsBoardId: string,
  subitemColumnId: string,
  actionTypeColumnId: string,
  fileDocsColumnId: string,
  emailDocsColumnId: string[],
  connectedItemColumnId?: string,
  connectedDocColumnId?: string,
  removeMainItem?: boolean,
  invoiceColumnId?: string,
  invoiceApprovedColumnId?: string,
  searchDocsColumnId?: string,
  additionalInfoDocsColumnId?: string,
  continDocsColumnId?: string,
  proofedCommitmentDocsColumnId?: string,
  docsProofingColumnId?: string,
  lastFilesColumnId?: string,
  filesColumnId?: string
) => {
  let subitems: string[] = []
  let srcSubitems: any[] = []
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:moveEmails-subitemsQuery,dest item id:${destItemId}`
      )}`
    )
  const destRes = (await monday.api(subitemsQuery(destItemId))) as MonRes
  if (
    destRes &&
    destRes.data.items &&
    destRes.data.items.length &&
    destRes.data.items[0].subitems
  ) {
    subitems = destRes.data.items[0].subitems.map((s: any) => s.id)
  }
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:moveEmails-subitemsQuery,src item id:${srcItemId}`
      )}`
    )
  const srcRes = (await monday.api(
    subitemsQuery(srcItemId, [actionTypeColumnId]),
    {token: config.mondayApiToken}
  )) as MonRes
  if (
    srcRes &&
    srcRes.data.items &&
    srcRes.data.items.length &&
    srcRes.data.items[0].subitems
  ) {
    srcSubitems = srcRes.data.items[0].subitems
    subitems = subitems.concat(srcSubitems.map((s: any) => s.id))
  }
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:moveEmails-change_multiple_column_values,dest item id:${destItemId}`
      )}`
    )
  await monday.api(
    `mutation {
    change_multiple_column_values(
      item_id:${destItemId}, 
      board_id:${boardId}, 
      column_values: "{\\"${subitemColumnId}\\" : {\\"linkedPulseIds\\":[${subitems
      .map(s => `{\\"linkedPulseId\\":${s}}`)
      .join(',')}]}}") {
      id
    }
  }`,
    {token: config.mondayApiToken}
  )
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:moveEmails-change_multiple_column_values,src item id:${srcItemId}`
      )}`
    )
  await monday.api(
    `mutation {
    change_multiple_column_values(
      item_id:${srcItemId}, 
      board_id:${boardId}, 
      column_values: "{\\"${subitemColumnId}\\" : null}") {
      id
    }
  }`,
    {token: config.mondayApiToken}
  )
  for (let i = 0; i < srcSubitems.length; i++) {
    await updateActionColumns(
      subitemsBoardId,
      srcSubitems[i].id,
      actionTypeColumnId,
      srcSubitems[i].column_values[0].text ??
        '' + actionToString(ActionType.MoveByFix, srcItemId, user)
    )
  }
  if (invoiceColumnId) {
    const invoiceAssets = await getFileDocs([srcItemId], [invoiceColumnId])
    if (invoiceAssets?.length) {
      await copyAssets(
        destItemId,
        invoiceColumnId,
        await getAssetsPublicUrls(invoiceAssets)
      )
    }
  }
  if (invoiceApprovedColumnId) {
    const invoiceAssets = await getFileDocs(
      [srcItemId],
      [invoiceApprovedColumnId]
    )
    if (invoiceAssets?.length) {
      await copyAssets(
        destItemId,
        invoiceApprovedColumnId,
        await getAssetsPublicUrls(invoiceAssets)
      )
    }
  }
  var lastBoardConfig
  //move between boards
  if (lastBoardId) {
    lastBoardConfig = getConfigByParentBoard(lastBoardId)
  }
  if (lastBoardConfig) {
    await moveColumnsBetweenBoards(
      srcItemId,
      destItemId,
      lastFilesColumnId,
      lastBoardConfig
    )
  } else
    await moveDocsColumns(
      srcItemId,
      destItemId,
      searchDocsColumnId,
      additionalInfoDocsColumnId,
      continDocsColumnId,
      proofedCommitmentDocsColumnId,
      docsProofingColumnId,
      lastFilesColumnId,
      filesColumnId
    )

  const fileAssetsIds = await getFileDocs([srcItemId], [fileDocsColumnId])
  if (fileAssetsIds?.length) {
    var id =
      connectedItemColumnId &&
      (await getConnectedItem(destItemId, connectedItemColumnId))
    if (id)
      await copyAssets(
        id,
        connectedDocColumnId || fileDocsColumnId,
        await getAssetsPublicUrls(fileAssetsIds)
      )
  }
  if (srcSubitems.length > 0) {
    const assetsIds = await getFileDocs(
      srcSubitems.map((s: any) => s.id),
      emailDocsColumnId
    )

    if (assetsIds?.length) {
      const itemForDocs = connectedItemColumnId
        ? (await getConnectedItem(destItemId, connectedItemColumnId)) ||
          destItemId
        : destItemId
      await copyAssets(
        itemForDocs,
        connectedDocColumnId || fileDocsColumnId,
        await getAssetsPublicUrls(assetsIds)
      )
    }
  }

  await fixFileNumber({
    action: 'move',
    srcItemId,
    destItemId,
    boardId
  })
  var isMoveBetweenBoards = lastBoardId !== ''
  await updateEmailsParentId(
    srcItemId,
    destItemId,
    boardId,
    fileNumber,
    isMoveBetweenBoards
  )
  if (removeMainItem && connectedItemColumnId) {
    var mainItemId = await getConnectedItem(srcItemId, connectedItemColumnId)
    if (mainItemId) {
      if (config.sendMondayLogsUrl.length > 0)
        await fetch(
          `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
            `function:fixFileNumber-delete_item,main item id:${mainItemId}`
          )}`
        )

      await monday.api(
        `mutation {
      delete_item (item_id: ${mainItemId}) {
        id
      }
    }`,
        {token: config.mondayApiToken}
      )
    }
  }
  addUpdatesToItem(srcItemId, destItemId, user.id)
}

export const renameFile = async (
  lastBoardId: string,
  boardId: string,
  itemId: string,
  newName: string,
  lastFilesColumnId?: string,
  underwriterColumnId?: string,
  userId?: number
) => {
  if (lastBoardId) {
    var newItemId = await updateEmailsFileNumber(
      itemId,
      newName,
      lastBoardId,
      boardId,
      userId || 0
    )
    moveColumnsBetweenBoards(
      itemId,
      newItemId.toString(),
      lastFilesColumnId,
      getConfigByParentBoard(lastBoardId)
    )
  } else {
    await updateName(boardId, itemId, newName)
    await updateEmailsFileNumber(itemId, newName, '', '', userId || 0)
  }

  //remove the V from underwriter column
  if (underwriterColumnId)
    await updateCheckedColumn(underwriterColumnId, boardId, itemId, false)
  await fixFileNumber({
    action: 'fix',
    itemId,
    boardId,
    newName
  })
  if (lastBoardId) await archiveItem(itemId)
}

export const updateName = async (
  boardId: string,
  itemId: string,
  newName: string
) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:updateName-change_multiple_column_values,board id: ${boardId}, item id:${itemId}`
      )}`
    )

  await monday.api(
    `mutation {
        change_multiple_column_values(
          item_id:${itemId}, 
          board_id:${boardId}, 
          column_values: "{\\"name\\" : \\"${newName
            .replaceAll('\\', '\\\\\\')
            .replaceAll('<', '(')
            .replaceAll('>', ')')
            .replaceAll('"', "'")}\\"}") {
          id
        }
      }`,
    {token: config.mondayApiToken}
  )
}

export const updateActionColumns = async (
  boardId: string,
  itemId: string,
  actionTypeColumnId: string,
  actionType: string
) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:updateActionColumns-change_multiple_column_values,board id: ${boardId}, item id:${itemId}`
      )}`
    )

  monday.api(
    `mutation {
        change_multiple_column_values(
          item_id:${itemId}, 
          board_id:${boardId}, 
          column_values:"{\\"${actionTypeColumnId}\\":\\"${actionType}\\"}") {
          id
        }
      }`,
    {token: config.mondayApiToken}
  )
}

export const updateHandled = async (
  columnId: string,
  boardId: string,
  itemId: string,
  handled: boolean
) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:updateHandled-change_multiple_column_values,board id: ${boardId}, item id:${itemId}, column id: ${columnId} `
      )}`
    )
  monday.api(
    `mutation {
        change_multiple_column_values(
          item_id:${itemId}, 
          board_id:${boardId}, 
          column_values: "{\\"${columnId}\\" : ${
      handled ? `{\\"checked\\" : \\"true\\"}` : 'null'
    }}") {
          id
        }
      }`,
    {token: config.mondayApiToken}
  )
}
export const updateCheckedColumn = async (
  columnId: string,
  boardId: string,
  itemId: string,
  value: boolean
) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:updateCheckedColumn-change_multiple_column_values,board id: ${boardId}, item id:${itemId}, column id: ${columnId} `
      )}`
    )

  monday.api(
    `mutation {
      change_multiple_column_values(
        item_id:${itemId}, 
        board_id:${boardId}, 
        column_values: "{\\"${columnId}\\" : ${
      value ? `{\\"checked\\" : \\"true\\"}` : 'null'
    }}") {
        id
      }
    }`,
    {token: config.mondayApiToken}
  )
}
export const updateAssignee = async (
  columnId: string,
  boardId: string,
  itemId: string,
  userId?: number
) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:updateAssignee-change_multiple_column_values,board id: ${boardId}, item id:${itemId}, column id: ${columnId} `
      )}`
    )

  monday.api(
    `mutation {
        change_multiple_column_values(
          board_id: ${boardId},
          item_id: ${itemId},
          column_values: "{\\"${columnId}\\":${
      !userId
        ? 'null'
        : `{\\"personsAndTeams\\":[{\\"id\\":${userId},\\"kind\\":\\"person\\"}]}`
    }}",
          create_labels_if_missing: true
        ) {
          id
        }
      }`,
    {token: config.mondayApiToken}
  )
}

export const getAssetsPublicUrls = async (ids: string[]) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getAssetsPublicUrls ids: ${ids.join(',')}`
      )}`
    )

  const assetsRes = (await monday.api(`query {
          assets (ids:[${ids.join(',')}]) {
              public_url
          }
      }`)) as MonRes
  if (assetsRes?.data.assets?.length)
    return assetsRes.data.assets.map((a: MonAsset) => a.public_url) as string[]
  return []
}

const convertOriginValuesToDest = (
  originalEmail: Subitem,
  originColumnIds: ColumnIds,
  destColumnIds: ColumnIds
) => {
  const columnValues = Object.keys(destColumnIds).reduce((obj, key) => {
    const keyOfColumnIds = key as keyof ColumnIds
    const destColumn = destColumnIds[keyOfColumnIds]
    if (destColumn && destColumn.shouldCopy !== false) {
      const originId = originColumnIds[keyOfColumnIds]?.id
      if (originId) {
        const columnValue = originalEmail.columnValues.find(
          c => c.id === originId
        )
        if (columnValue) {
          obj[destColumn.id] =
            destColumn?.shouldCopy === 'value'
              ? columnValue.value.startsWith('{"checked"')
                ? columnValue.text === 'v'
                  ? JSON.parse('{"checked" : "true"}')
                  : null
                : JSON.parse(columnValue.value)
              : columnValue.text ?? ''
        }
      }
    }
    return obj
  }, {} as any)
  return columnValues
}

const onCopyEmailsFailure = async (
  action: string,
  userId: number,
  emails: Subitem[],
  files: MonFile[]
) => {
  for (let i = 0; i < emails.length; i++) {
    for (let j = 0; j < files.length; j++) {
      await sendNotification(
        `Failed to ${action} email "${emails[i].name}" to "${files[j].name}".`,
        userId,
        emails[i].id
      )
    }
  }
  return {
    isSucceeded: false,
    message: `Failed to ${action} email${emails.length ? 's' : ''}`
  }
}

export const copyEmails = async (
  originalEmails: Subitem[],
  files: MonFile[],
  originColumnIds: ColumnIds,
  originBoardId: string,
  destBoardConfig: Config,
  deleteOrigin: boolean,
  user: User
) => {
  const destFiles = files.filter(f => f.id !== originalEmails[0].parent.id)
  const actionType = deleteOrigin ? ActionType.Move : ActionType.Copy
  try {
    const res = await fetch(config.moveOrCopyUrl, {
      method: 'POST',
      body: JSON.stringify({
        srcEmails: originalEmails.map(e => ({
          id: e.id,
          name: e.name,
          columnValues: JSON.stringify({
            ...convertOriginValuesToDest(
              e,
              originColumnIds,
              destBoardConfig.columnIds!
            ),
            [destBoardConfig.columnIds!.actionType.id]:
              (actionType === ActionType.Move
                ? getEmailColumnValue(e, originColumnIds.actionType)
                : '') + actionToString(actionType, e.parent.id, user)
          }),
          docs: e.docs.map(d => ({id: d.assetId})),
          attachedEmails: e.attachedEmails.map(a => ({id: a.assetId})),
          parentId: e.parent.id,
          invoices: e.invoice?.map(a => ({id: a.assetId}))
        })),
        destFiles,
        actionInformation: {
          srcBoardId: originBoardId,
          destBoardId: destBoardConfig.parentBoard.id,
          deleteSrc: deleteOrigin,
          userId: user.id,
          destDocsColumnId: destBoardConfig.columnIds?.docs.id,
          destAttachedEmailsColumnId:
            destBoardConfig.columnIds?.attachedEmails?.id,
          destInvoiceColumnId: destBoardConfig.columnIds?.invoice?.id,
          filesBoardConfig: {
            linkedBoardId: destBoardConfig.parentBoard.linkedBoardId,
            linkedItemColumnId:
              destBoardConfig.parentBoard.linkedItemColumn?.id,
            docsColumnId: destBoardConfig.parentBoard.docsColumn.id,
            linkedDocsColumnId:
              destBoardConfig.parentBoard.linkedDocsColumn?.id,
            isStatusColumnMirror:
              destBoardConfig.parentBoard.statusColumn?.isMirror,
            statusColumnId: destBoardConfig.parentBoard.statusColumn?.id,
            newMailStatusId:
              destBoardConfig.parentBoard.statusColumn?.newMailId,
            invoiceColumnId: destBoardConfig.parentBoard.invoiceColumn?.id
          }
        }
      })
    })
    if (!res.ok) {
      throw new Error(res.statusText)
    }

    const data = await res.json()
    const statusUri = data.statusQueryGetUri

    let statusResJson: any
    do {
      await new Promise(resolve => setTimeout(resolve, 5000))
      const statusRes = await fetch(statusUri)
      statusResJson = await statusRes.json()
    } while (statusResJson.runtimeStatus !== 'Completed')
    let isSucceeded = statusResJson.customStatus.Status === 'Completed'
    const result = statusResJson.customStatus.Data
    let message = ''
    if (isSucceeded) {
      const action = deleteOrigin ? 'moved' : 'copied'
      if (originalEmails.length === 1) {
        const successLength = result[0].FileResults.filter(
          (r: any) => r.IsSucceeded
        ).length
        if (!successLength) {
          isSucceeded = false
        } else if (successLength !== destFiles.length) {
          message = `Email ${originalEmails[0].name} was ${action} successfully to ${successLength} of ${destFiles.length} files`
        }
      } else {
        const successLength = result.filter(
          (r: any) => r.FileResults[0].IsSucceeded
        ).length
        if (!successLength) {
          isSucceeded = false
        } else if (successLength !== originalEmails.length) {
          message = `${successLength} of ${originalEmails.length} emails was ${action} successfully to file ${destFiles[0]}`
        }
      }
    }
    return {
      isSucceeded: isSucceeded && !message,
      message: message
    }
  } catch (ex) {
    console.log(ex)
    return await onCopyEmailsFailure(
      deleteOrigin ? 'move' : 'copy',
      user.id,
      originalEmails,
      destFiles
    )
  }
}

const assetQuery = (assetsId: string[]) => `query {
  assets (ids:[${assetsId.join(',')}]) {
    id
    url
    name
    created_at
    url_thumbnail 
    public_url
    file_size
  }
}`

export const getAssets = async (assetsIds: string[]) => {
  const assets: Asset[] = []
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getAssets, ids: ${assetsIds.join(',')}`
      )}`
    )

  const res = (await monday.api(assetQuery(assetsIds))) as MonRes
  if (res && res.data.assets) {
    for (let i = 0; i < res.data.assets.length; i++) {
      const monAsset = res.data.assets[i]
      assets.push({
        id: monAsset.id ?? '',
        name: monAsset.name ?? '',
        createdAt: monAsset.created_at!,
        url: monAsset.url ?? '',
        urlThumbnail: monAsset.url_thumbnail ?? '',
        publicUrl: monAsset.public_url ?? '',
        size: monAsset.file_size,
        fileExtension: monAsset.file_extension
      })
    }
  }
  return assets
}

const emailQuery = (
  emailId: string | string[],
  columnsFromParent: string[]
) => `query {
  items(ids:[${(Array.isArray(emailId) ? emailId : [emailId]).join(',')}]) {
    id
    name
    parent_item{
      id
      name
      board{
        id
      }
      column_values(ids:["${columnsFromParent.join('","')}"]){
        id
        value
        text
        ... on BoardRelationValue
        {
          display_value
        }
        ... on MirrorValue 
        {
         display_value
        }
      }
    }
    column_values{
      id
      value
      text
      ... on BoardRelationValue
      {
        display_value
      }
      ... on MirrorValue 
      {
        display_value
      }
    }
  }
}`

export const getEmails = async (
  emailIds: string[],
  columnIds: ColumnIds,
  readHtml: boolean = true
) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getEmails-emailQuery, ids: ${emailIds.join(',')}`
      )}`
    )
  const res = (await monday.api(
    emailQuery(
      emailIds,
      Object.values(columnIds)
        .filter(value => value.fromParent)
        .map(value => value.id)
    )
  )) as MonRes
  const emails: Subitem[] = []
  if (res && res.data.items) {
    const monEmails = res.data.items
    for (let i = 0; i < monEmails.length; i++) {
      const monItem = monEmails[i]
      const email = {} as Subitem
      email.id = monItem.id ?? ''
      email.parent = {
        id: monItem.parent_item?.id ?? '',
        name: monItem.parent_item?.name ?? ''
      }
      if (readHtml) {
        email.html = await getEmailHtml(
          monItem.parent_item?.board?.id ?? '',
          monItem.id ?? ''
        )
      }
      emails.push(resToEmail(monItem, email, columnIds))
    }
  }
  return emails.sort((a, b) => b.date.getTime() - a.date.getTime())
}

export const getEmail = async (
  emailId: string,
  columnIds: ColumnIds,
  readHtml: boolean = true
) => {
  const res = await getEmails([emailId], columnIds, readHtml)
  return res.length ? res[0] : undefined
}

export const getEmailHtml = async (parentBoardId: string, emailId: string) => {
  try {
    const res = await fetch(
      `${config.mailBodyUrl}&boardId=${parentBoardId}&itemId=${emailId}`
    )
    if (res.status !== 404) return removeSendGridImage(await res.text())
  } catch {}
  return "Email body wasn't found"
}

export const resToEmail = (
  monItem: any,
  email: Subitem,
  columnIds: ColumnIds
) => {
  email.name = monItem.name
  email.columnValues = monItem.column_values
  email.subject = columnIds.subject
    ? getColumnValue(columnIds.subject, monItem)
    : monItem.name
  email.from = getColumnValue(columnIds.from, monItem)
  email.to = getColumnValue(columnIds.to, monItem)
  email.cc = getColumnValue(columnIds.cc, monItem)
  email.date = new Date(getColumnValue(columnIds.dateReceived, monItem))
  email.content = getColumnValue(columnIds.body, monItem)
  email.assignee = getColumnValue(columnIds.assigned, monItem)
    ? ({
        id: JSON.parse(getColumnValue(columnIds.assigned, monItem))
          ?.personsAndTeams[0].id
      } as User)
    : ({} as User)
  email.handled = getColumnValue(columnIds.handled, monItem) === 'v'
  email.isPriority = getColumnValue(columnIds.importance, monItem) === 'v'
  email.invoice = []
  if (columnIds.invoice) {
    const attachedEmailsValue = getColumnValue(columnIds.invoice, monItem)
    if (attachedEmailsValue) {
      email.invoice = JSON.parse(attachedEmailsValue).files
    }
  }
  email.docs =
    (getColumnValue(columnIds.docs, monItem) &&
      JSON.parse(getColumnValue(columnIds.docs, monItem)).files) ||
    []
  email.attachedEmails = []
  if (columnIds.attachedEmails) {
    const attachedEmailsValue = getColumnValue(
      columnIds.attachedEmails,
      monItem
    )
    if (attachedEmailsValue)
      email.attachedEmails = email.attachedEmails.concat(
        JSON.parse(attachedEmailsValue).files
      )
  }
  if (columnIds.sourceFolder) {
    email.sourceFolder = getColumnValue(columnIds.sourceFolder, monItem)
  }
  if (columnIds.sentViaMonday)
    email.sentViaMonday =
      getColumnValue(columnIds.sentViaMonday, monItem) === 'v'
  return email
}

const getColumnValue = (column: Column, monItem: any) => {
  const columnValue = (
    column.fromParent ? monItem.parent_item : monItem
  ).column_values.find((c: any) => c.id === column.id)
  return column.readValue
    ? columnValue?.value
    : columnValue?.display_value ?? columnValue?.text ?? ''
}
export const addUpdatesToItem = async (
  srcItemId: string,
  destItemId: string,
  userId: number
) => {
  try {
    const res = await fetch(config.copyUpdates, {
      method: 'POST',
      body: JSON.stringify({
        src: srcItemId,
        dest: destItemId,
        userId: userId
      })
    })
    return !res.ok
  } catch {
    return false
  }
}

export const OpenSendMailModal = async (
  boardId: string,
  parentId: string,
  type: string,
  AAK?: boolean,
  email?: Subitem,
  assets?: Asset[],
  messageId?: string,
  conversationId?: string
) => {
  const fn = parentId ? await getNameByFileId(parentId) : ''
  const emailId = email?.id || parentId + '-1'
  localStorage.setItem(
    emailId,
    JSON.stringify({
      ...email,
      fn: fn,
      messageId: messageId,
      conversationId: conversationId
    })
  )
  localStorage.setItem(emailId + '-assets', JSON.stringify(assets || []))

  monday
    // @ts-ignore
    .execute('openAppFeatureModal', {
      url: window.location.origin + '/mail-box/send',
      urlParams: {
        boardId,
        parentId: parentId,
        type: type,
        emailId: emailId,
        AAK: AAK
      },
      width: '1000px',
      height: '90vh'
    })
    .then(() => {
      localStorage.removeItem(emailId)
      localStorage.removeItem(emailId + '-assets')
    })
}

export const getSubitems = async (itemId: string, columnIds: string[]) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getSubitems-subitemsQuery item id: ${itemId}`
      )}`
    )

  const res = (await monday.api(subitemsQuery(itemId, columnIds), {
    token: config.mondayApiToken
  })) as MonRes
  if (
    res &&
    res.data.items &&
    res.data.items.length &&
    res.data.items[0].subitems
  ) {
    return res.data.items[0].subitems as MonItem[]
  }
}
