import {useEffect} from 'react'
import config from 'src/config'

export default function AuthorizeDropbox() {
  useEffect(() => {
    const authorizeDropbox = () => {
      // Check if the authorization code is available in the URL query params
      const urlParams = new URLSearchParams(window.location.search)
      const authorizationCode = urlParams.get('code')

      if (authorizationCode) {
        // Exchange the authorization code for an access token
        fetch('https://api.dropbox.com/oauth2/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            grant_type: 'authorization_code',
            code: authorizationCode,
            client_id: config.dropboxClientId,
            redirect_uri: config.dropboxRedirectUri,
            client_secret: config.dropboxClientSecret
          })
        })
          .then(response => response.json())
          .then(data => {
            const {access_token} = data
            localStorage.setItem('dropbox-token', access_token)
            window.close()
          })
          .catch(error => {
            console.error(
              'Error exchanging authorization code for access token:',
              error
            )
          })
      } else {
      }
    }
    authorizeDropbox()
  }, [])
  return (
    <div>
      <h1>Authorizing...</h1>
    </div>
  )
}
