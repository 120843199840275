export interface Column {
  id: string
  readValue?: boolean
  shouldCopy?: false | 'value' | 'text'
  fromParent?: boolean
}
export interface ColumnIds {
  requestType?: Column
  from: Column
  dateReceived: Column
  body: Column
  assigned: Column
  handled: Column
  sourceFolder?: Column
  subject?: Column
  docs: Column
  attachedEmails?: Column
  to: Column
  cc: Column
  important?: Column
  importance: Column
  actionType: Column
  sentViaMonday?: Column
  question?: Column
  invoice?: Column
}

export interface Board {
  id: string
  name: string
}

export interface ParentBoardConfig {
  id: string
  linkedBoardId: string
  statusColumn?: {
    id: string
    newMailId: number
    deleteMailId?: number
    isMirror: boolean
  }
  docsColumn: {id: string}
  subitemsColumn: {id: string}
  linkedDocsColumn?: {id: string}
  linkedItemColumn?: {id: string}
  defaultSubjectColumn: {id: string}
  invoiceColumn?: {id: string}
  invoiceApprovedColumn?: {id: string}
  isCopyFNCorrespondence?: boolean
  underwriter?: {id: string}
  underwriterDone?: {id: string}
  partnerIdColumn?: {id: string}
  supporterColumn?: {id: string}
  lastFiles?: {id: string}
  files?: {id: string}
  searchDocsColumn?: {id: string}
  additionalInfoDocsColumn?: {id: string}
  continDocsColumn?: {id: string}
  proofedCommitmentDocsColumn?: {id: string}
  docsProofingColumn?: {id: string}
  contactsNameColumn?: {id: string}
  contactsEmailAddreassColumn?: {id: string}
  filledFormColumn?: {id: string}
  letterReminderColumn?: {id: string}
  activeColumn?: {
    id: string
    activeValue: string
  }
}

export interface Config {
  parentBoard: ParentBoardConfig
  sender: string | {[key: string]: string}
  sendToSender?: boolean
  moveOrCopy: {
    destinationBoards: Board[]
    hideCreateFN?: boolean
  }
  columnIds?: ColumnIds
  removeMainItem?: boolean
  showOnlyNotHandled?: boolean
  autoAssign?: boolean
  signature?: string
  departmentSignature?: string
  addFNToSubject?: boolean
  isShowUnReadMails?: boolean
  isMergeBetweenBoards?: boolean
  isShowFNCorrespondence?: boolean
}

const config: Record<string, Config> = {
  '1926306659': {
    //subitems NY - Dev (emails app)
    parentBoard: {
      id: '1926306601',
      linkedBoardId: '1598012350',
      statusColumn: {
        id: 'status27',
        newMailId: 7,
        deleteMailId: 5,
        isMirror: true
      },
      docsColumn: {id: 'mirror813'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'mirror'},
      subitemsColumn: {id: 'subitems4'},
      defaultSubjectColumn: {id: 'text'}
    },
    sender: 'hswimmer@madisoncres.com',
    addFNToSubject: true,
    sendToSender: true,
    moveOrCopy: {
      destinationBoards: [
        {id: '1926306601', name: 'NY - Dev (emails app) (This Board)'},
        {id: '1987474195', name: 'NY - Dev - Reader Tasks (emails app)'}
      ],
      hideCreateFN: false
    },
    autoAssign: true,
    columnIds: {
      requestType: {id: 'dropdown', shouldCopy: 'value'},
      from: {id: 'text7'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text3'},
      assigned: {
        id: 'people',
        readValue: true,
        shouldCopy: false
      },
      handled: {id: 'checkbox', shouldCopy: false},
      sourceFolder: {id: 'text'},
      subject: {id: 'text0'},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      to: {id: 'text5'},
      cc: {id: 'text9'},
      actionType: {id: 'text8', shouldCopy: false},
      importance: {id: 'checkbox', shouldCopy: 'value'}
    }
  },
  '1987474452': {
    //subitems NY - Dev - Reader Tasks (emails app)
    parentBoard: {
      id: '1987474195',
      linkedBoardId: '1598012350',
      statusColumn: {
        id: 'status0',
        newMailId: 7,
        deleteMailId: 5,
        isMirror: true
      },
      docsColumn: {id: 'mirror813'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'mirror'},
      subitemsColumn: {id: 'subitems4'},
      defaultSubjectColumn: {id: 'TODO::'}
    },
    sender: 'hswimmer@madisoncres.com',
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1987474195',
          name: 'NY - Dev - Reader Tasks (emails app) (This Board)'
        },
        {id: '1926306601', name: 'NY - Dev (emails app)'}
      ]
    },
    columnIds: {
      requestType: {id: 'dropdown', shouldCopy: 'value'},
      from: {id: 'text7'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text3'},
      assigned: {id: 'people', readValue: true, shouldCopy: false},
      handled: {id: 'checkbox', shouldCopy: false},
      sourceFolder: {id: 'text'},
      subject: {id: 'text0'},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      to: {id: 'text5'},
      cc: {id: 'text9'},
      actionType: {id: '', shouldCopy: false},
      importance: {id: 'checkbox', shouldCopy: 'value'}
    }
  },
  '1598012844': {
    //subitems NY Searchers Board
    parentBoard: {
      id: '1598012833',
      linkedBoardId: '1598012350',
      statusColumn: {id: 'status1', newMailId: 8, isMirror: true},
      docsColumn: {id: 'mirror7'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'connect_boards'},
      subitemsColumn: {id: 'subitems4'},
      defaultSubjectColumn: {id: 'text5'}
    },
    sender: 'nysearchrequests@madisontitle.com',
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1598012833',
          name: 'NY Searchers Board (This Board)'
        },
        {id: '1598013814', name: 'Readers Board'},
        {id: '1643112781', name: 'Work/Day Work Board'}
      ]
    },
    columnIds: {
      from: {id: 'text7'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text3'},
      assigned: {
        id: 'people',
        readValue: true,
        shouldCopy: false,
        fromParent: true
      },
      handled: {id: 'checkbox', shouldCopy: false},
      sourceFolder: {id: 'text'},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      to: {id: 'text5'},
      cc: {id: 'text9'},
      actionType: {id: 'action_type', shouldCopy: false},
      importance: {id: 'checkbox5', shouldCopy: 'value'}
    }
  },
  '1598013820': {
    //subitems NY Readers Board
    parentBoard: {
      id: '1598013814',
      linkedBoardId: '1598012350',
      statusColumn: {id: 'searcher_status', newMailId: 8, isMirror: true},
      docsColumn: {id: 'mirror7'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'link_to_ny___main8'},
      subitemsColumn: {id: 'subitems4'},
      defaultSubjectColumn: {id: 'text7'}
    },
    sender: 'faxny@madisoncres.com',
    moveOrCopy: {
      destinationBoards: []
    },
    columnIds: {
      from: {id: 'text'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text38'},
      assigned: {
        id: 'people',
        readValue: true,
        shouldCopy: false,
        fromParent: true
      },
      handled: {id: 'checkbox1', shouldCopy: false},
      sourceFolder: {id: 'text3'},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      to: {id: 'text1'},
      cc: {id: 'text30'},
      actionType: {id: 'action_type', shouldCopy: false},
      importance: {id: 'checkbox', shouldCopy: 'value'}
    }
  },
  '1643112794': {
    //subitems Work/Day Work Board
    parentBoard: {
      id: '1643112781',
      linkedBoardId: '1598012350',
      statusColumn: {
        id: 'dup__of_readers_status',
        newMailId: 8,
        isMirror: true
      },
      docsColumn: {id: 'mirror813'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'link_to_ny___main'},
      subitemsColumn: {id: 'subitems4'},
      defaultSubjectColumn: {id: 'text2'}
    },
    sender: {
      NYDayWork: 'nydaywork@madisoncres.com',
      'NY-Work': 'nywork@madisontitle.com',
      'nywork@madisontitle.com': 'nywork@madisontitle.com',
      'NYDayWork@madisoncres.com': 'nydaywork@madisoncres.com',
      '': 'nywork@madisontitle.com'
    },
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1643112781',
          name: 'Work/Day Work Board (This Board)'
        }
      ]
    },
    showOnlyNotHandled: true,
    autoAssign: true,
    columnIds: {
      requestType: {id: 'dropdown', shouldCopy: 'value'},
      from: {id: 'text7'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text3'},
      assigned: {id: 'people', readValue: true, shouldCopy: false},
      handled: {id: 'checkbox', shouldCopy: false},
      sourceFolder: {id: 'text'},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      to: {id: 'text5'},
      cc: {id: 'text9'},
      actionType: {id: 'action_type', shouldCopy: false},
      importance: {id: 'checkbox6', shouldCopy: 'value'}
    }
  },
  '2145075472': {
    //subitems National Survey Board
    parentBoard: {
      id: '2145075006',
      linkedBoardId: '2145098921',
      statusColumn: {id: 'cb_op_status', newMailId: 12, isMirror: false},
      docsColumn: {id: 'mirror7'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'connect_boards22'},
      subitemsColumn: {id: 'subitems6'},
      defaultSubjectColumn: {id: 'default_subject_line'},
      underwriter: {id: 'check0'},
      underwriterDone: {id: 'checkbox9'}
    },
    sender: 'ncsrequests@madisontitle.com',
    sendToSender: true,
    signature: 'Survey Department',
    addFNToSubject: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '2145075006',
          name: 'National Survey Board (This Board)'
        },
        {
          id: '2145079305',
          name: 'National Survey Multisites Board'
        }
      ]
    },

    columnIds: {
      from: {id: 'text0'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'message'},
      assigned: {
        id: 'people',
        readValue: true,
        shouldCopy: false,
        fromParent: true
      },
      handled: {id: 'check', shouldCopy: false},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      attachedEmails: {id: 'files2', readValue: true, shouldCopy: false},
      to: {id: 'text'},
      cc: {id: 'text19'},
      important: {id: 'text06'},
      actionType: {id: 'text9', shouldCopy: false},
      importance: {id: 'checkbox', shouldCopy: 'value'}
    }
  },
  '2145079397': {
    //subitems National Survey Multisites Board
    parentBoard: {
      id: '2145079305',
      linkedBoardId: '2145098921',
      statusColumn: {id: 'survey_status', newMailId: 12, isMirror: false},
      docsColumn: {id: 'mirror'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'connect_boards'},
      subitemsColumn: {id: 'subitems'},
      defaultSubjectColumn: {id: 'default_subject_line'},
      underwriter: {id: 'checkbox1'},
      underwriterDone: {id: 'checkbox7'}
    },
    sender: 'ncsrequests@madisontitle.com',
    sendToSender: true,
    signature: 'Survey Department',
    addFNToSubject: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '2145079305',
          name: 'National Survey Multisites Board (This Board)'
        }
      ],
      hideCreateFN: true
    },
    columnIds: {
      from: {id: 'text4'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text8'},
      assigned: {
        id: 'person',
        readValue: true,
        shouldCopy: false,
        fromParent: true
      },
      handled: {id: 'check', shouldCopy: false},
      importance: {id: 'checkbox', shouldCopy: 'value'},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      attachedEmails: {id: 'files0', readValue: true, shouldCopy: false},
      to: {id: 'text'},
      cc: {id: 'text5'},
      actionType: {id: 'text17', shouldCopy: false}
    }
  },
  '1493677288': {
    //subitems National Proformas Tasks Board
    parentBoard: {
      id: '1224694202',
      linkedBoardId: '',
      statusColumn: {id: 'status', newMailId: 12, isMirror: false},
      docsColumn: {id: 'files'},
      subitemsColumn: {id: 'subitems6'},
      defaultSubjectColumn: {id: 'default_subject_line'},
      underwriter: {id: 'checkbox_13'},
      underwriterDone: {id: 'checkbox_18'}
    },
    sender: 'natl-proformas-newreqs@madisontitle.com',
    isShowFNCorrespondence: false,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1224694202',
          name: 'National Proformas Tasks (This Board)'
        },
        {
          id: '1605478152',
          name: 'National Proformas - Multi Sites Tasks'
        }
      ]
    },

    columnIds: {
      from: {id: 'text0'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text4'},
      assigned: {
        id: 'person',
        readValue: true,
        shouldCopy: false,
        fromParent: true
      },
      handled: {id: 'check', shouldCopy: false},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      attachedEmails: {id: 'files2', readValue: true, shouldCopy: false},
      to: {id: 'text41'},
      cc: {id: 'text48'},
      actionType: {id: 'text26', shouldCopy: false},
      importance: {id: 'checkbox3', shouldCopy: 'value'}
    }
  },
  '1606573883': {
    //subitems National Proformas - Multi Sites Tasks Board
    parentBoard: {
      id: '1605478152',
      linkedBoardId: '',
      statusColumn: {id: 'status', newMailId: 12, isMirror: false},
      docsColumn: {id: 'files'},
      subitemsColumn: {id: 'subitems'},
      defaultSubjectColumn: {id: 'default_subject_line'},
      underwriter: {id: 'checkbox26'},
      underwriterDone: {id: 'checkbox6'}
    },
    sender: 'natl-proformas-newreqs@madisontitle.com',
    isShowFNCorrespondence: false,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1605478152',
          name: 'National Proformas - Multi Sites Tasks (This Board)'
        }
      ],
      hideCreateFN: true
    },
    columnIds: {
      from: {id: 'text4'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text8'},
      assigned: {
        id: 'person',
        readValue: true,
        shouldCopy: false,
        fromParent: true
      },
      handled: {id: 'check', shouldCopy: false},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      attachedEmails: {id: 'files0', readValue: true, shouldCopy: false},
      to: {id: 'text3'},
      cc: {id: 'text37'},
      actionType: {id: 'action_type', shouldCopy: false},
      importance: {id: 'checkbox2', shouldCopy: 'value'}
    }
  },
  '2891211859': {
    //subitems Dev- Proformas Tasks Board
    parentBoard: {
      id: '2891211747',
      linkedBoardId: '2891208673',
      statusColumn: {id: 'status', newMailId: 12, isMirror: false},
      docsColumn: {id: 'mirror12'},
      linkedDocsColumn: {id: 'files'},
      //link to Dev- National Main
      linkedItemColumn: {id: 'link_to_dev__national_main'},
      subitemsColumn: {id: 'subitems6'},
      defaultSubjectColumn: {id: 'default_subject_line'},
      isCopyFNCorrespondence: true,
      underwriter: {id: 'checkbox8'},
      underwriterDone: {id: 'checkbox3'}
    },
    sender: 'nationalproformatitleapp@madisoncres.com',
    isShowUnReadMails: true,
    isShowFNCorrespondence: false,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '2891211747',
          name: 'Dev- Proformas Tasks (This Board)'
        },
        {
          id: '2891213606',
          name: 'Dev - Proforma - Multi Sites Tasks'
        }
      ]
    },
    columnIds: {
      from: {id: 'text0'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text4'},
      assigned: {
        id: 'person',
        readValue: true,
        shouldCopy: false,
        fromParent: true
      },
      handled: {id: 'check', shouldCopy: false},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      attachedEmails: {id: 'files2', readValue: true, shouldCopy: false},
      to: {id: 'text41'},
      cc: {id: 'text48'},
      actionType: {id: 'text26', shouldCopy: false},
      importance: {id: 'checkbox', shouldCopy: 'value'}
    }
  },
  '2891213731': {
    //subitems Dev - Proforma - Multi Sites Tasks Board
    parentBoard: {
      id: '2891213606',
      linkedBoardId: '2891208673',
      statusColumn: {id: 'status', newMailId: 12, isMirror: false},
      docsColumn: {id: 'mirror'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'link_to_dev__national_main'},
      subitemsColumn: {id: 'subitems6'},
      defaultSubjectColumn: {id: 'default_subject_line'},
      isCopyFNCorrespondence: true,
      underwriter: {id: 'checkbox3'},
      underwriterDone: {id: 'checkbox0'}
    },
    sender: 'nationalproformatitleapp@madisoncres.com',
    isShowFNCorrespondence: false,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '2891213606',
          name: 'Dev - Proforma - Multi Sites Tasks (This Board)'
        }
      ],
      hideCreateFN: true
    },
    columnIds: {
      from: {id: 'text4'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text8'},
      assigned: {
        id: 'person',
        readValue: true,
        shouldCopy: false,
        fromParent: true
      },
      handled: {id: 'check', shouldCopy: false},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      attachedEmails: {id: 'files0', readValue: true, shouldCopy: false},
      to: {id: 'text3'},
      cc: {id: 'text37'},
      actionType: {id: 'action_type', shouldCopy: false},
      importance: {id: 'checkbox', shouldCopy: 'value'}
    }
  },
  '3315277325': {
    //subitems NJ Readers Board
    parentBoard: {
      id: '3315266856',
      linkedBoardId: '3447242670',
      statusColumn: {
        id: 'mirror7',
        newMailId: 3,
        isMirror: true
      },
      docsColumn: {id: 'mirror0'},
      linkedDocsColumn: {id: 'files'},
      linkedItemColumn: {id: 'link_to_nj___main5'},
      subitemsColumn: {id: 'subitems'},
      defaultSubjectColumn: {id: 'text'},
      invoiceColumn: {id: 'files5'},
      invoiceApprovedColumn: {id: 'files3'}
    },
    sender: 'njtitleproduction@madisontitle.com',
    addFNToSubject: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '3315266856',
          name: 'NJ Readers (This Board)'
        }
      ]
    },
    removeMainItem: true,
    showOnlyNotHandled: true,
    autoAssign: true,
    columnIds: {
      from: {id: 'text'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'long_text'},
      assigned: {id: 'person', readValue: true, shouldCopy: false},
      handled: {id: 'checkbox', shouldCopy: false},
      attachedEmails: {id: 'files8', readValue: true, shouldCopy: false},
      sourceFolder: {id: 'text83'},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      to: {id: 'text3'},
      cc: {id: 'text17'},
      actionType: {id: 'text10', shouldCopy: false},
      sentViaMonday: {id: 'checkbox2', shouldCopy: 'value'},
      question: {id: 'boolean', shouldCopy: 'value'},
      importance: {id: 'checkbox6', shouldCopy: 'value'},
      invoice: {id: 'files0', shouldCopy: false, readValue: true}
    }
  },
  '3491714668': {
    //subitems NY- Readers & Proofers Board
    parentBoard: {
      id: '3491499581',
      defaultSubjectColumn: {id: 'text38'},
      linkedBoardId: '',
      subitemsColumn: {id: 'subitems'},
      docsColumn: {id: 'files'},
      searchDocsColumn: {id: 'files6'},
      additionalInfoDocsColumn: {id: 'files1'},
      continDocsColumn: {id: 'files9'},
      proofedCommitmentDocsColumn: {id: 'files3'},
      docsProofingColumn: {id: 'files'}
    },
    moveOrCopy: {
      destinationBoards: [
        {
          id: '3491499581',
          name: 'NY- Readers & Proofers (This Board)'
        }
      ]
    },
    sender: '',
    showOnlyNotHandled: true,
    addFNToSubject: true
  },
  '4113687668': {
    //subitems Mahwah Work board
    parentBoard: {
      id: '4113687545',
      linkedBoardId: '',
      statusColumn: {id: 'status', newMailId: 3, isMirror: false},
      docsColumn: {id: 'files'},
      subitemsColumn: {id: 'subitems4'},
      defaultSubjectColumn: {id: 'text2'}
    },
    sender: 'mmtproduction@madisonmahwah.com',
    showOnlyNotHandled: true,
    departmentSignature: 'MAHWAH',
    moveOrCopy: {
      destinationBoards: [
        {
          id: '4113687545',
          name: 'Mahwah Work board (This Board)'
        }
      ]
    },
    columnIds: {
      from: {id: 'text7'},
      dateReceived: {id: 'date0', shouldCopy: 'value'},
      body: {id: 'text3'},
      assigned: {id: 'people7', readValue: true, shouldCopy: false},
      handled: {id: 'checkbox7', shouldCopy: false},
      docs: {id: 'files', readValue: true, shouldCopy: false},
      to: {id: 'text5'},
      cc: {id: 'text9'},
      actionType: {id: 'action_type', shouldCopy: false},
      sentViaMonday: {id: 'checkbox81', shouldCopy: 'value'},
      importance: {id: 'checkbox14', shouldCopy: 'value'}
    }
  },
  '1206057338': {
    //Operations Test
    parentBoard: {
      id: '1206057338',
      linkedBoardId: '',
      docsColumn: {id: ''},
      subitemsColumn: {id: ''},
      defaultSubjectColumn: {id: ''},
      partnerIdColumn: {id: 'connect_boards93'},
      supporterColumn: {id: 'mirror_18'},
      lastFiles: {id: 'files_110'},
      files: {id: 'files2'}
    },
    sender: 'ChatAAKSGT@madisontitle.com',
    showOnlyNotHandled: true,
    isShowUnReadMails: true,
    isMergeBetweenBoards: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1206057338',
          name: 'Operations Test (This Board)'
        },
        {
          id: '1210244590',
          name: 'General Test'
        },
        {
          id: '1210276931',
          name: 'Test כוללים בתהליך הצטרפות'
        }
      ]
    }
  },
  '1210244590': {
    //General Test
    parentBoard: {
      id: '1210244590',
      linkedBoardId: '',
      docsColumn: {id: ''},
      subitemsColumn: {id: ''},
      defaultSubjectColumn: {id: ''},
      lastFiles: {id: 'files'}
    },
    sender: 'ChatAAKSGT@madisontitle.com',
    showOnlyNotHandled: true,
    isMergeBetweenBoards: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1210244590',
          name: 'General Test (This Board)'
        },
        {
          id: '1206057338',
          name: 'Operations Test'
        },
        {
          id: '1210276931',
          name: 'Test כוללים בתהליך הצטרפות'
        }
      ]
    }
  },
  '1210276931': {
    //Test כוללים בתהליך הצטרפות
    parentBoard: {
      id: '1210276931',
      linkedBoardId: '',
      docsColumn: {id: ''},
      subitemsColumn: {id: ''},
      defaultSubjectColumn: {id: ''},
      //פרוספקט
      additionalInfoDocsColumn: {id: '_______'},
      //לפחות 3 תמונות
      continDocsColumn: {id: 'file'},
      //תמונה ראש הכולל
      searchDocsColumn: {id: '__________________6'},
      //תמונה של בנין הכולל
      proofedCommitmentDocsColumn: {id: '___________________'}
    },
    sender: 'ChatAAKSGT@madisontitle.com',
    showOnlyNotHandled: true,
    isMergeBetweenBoards: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1210276931',
          name: 'Test כוללים בתהליך הצטרפות (This Board)'
        },
        {
          id: '1206057338',
          name: 'Operations Test'
        },
        {
          id: '1210244590',
          name: 'General Test'
        }
      ]
    }
  },
  '1127892758': {
    //Operations
    parentBoard: {
      id: '1127892758',
      linkedBoardId: '',
      docsColumn: {id: ''},
      subitemsColumn: {id: ''},
      defaultSubjectColumn: {id: ''},
      partnerIdColumn: {id: 'connect_boards93'},
      supporterColumn: {id: 'mirror_18'},
      lastFiles: {id: 'files_110'},
      files: {id: 'files2'}
    },
    sender: 'office@adoptakollel.org',
    showOnlyNotHandled: true,
    isShowUnReadMails: true,
    isMergeBetweenBoards: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1127892758',
          name: 'Operations (This Board)'
        },
        {
          id: '1143897065',
          name: 'General'
        },
        {
          id: '1204867316',
          name: 'כוללים בתהליך הצטרפות'
        }
      ]
    }
  },
  '1143897065': {
    //General
    parentBoard: {
      id: '1143897065',
      linkedBoardId: '',
      docsColumn: {id: ''},
      subitemsColumn: {id: ''},
      defaultSubjectColumn: {id: ''}
    },
    sender: 'office@adoptakollel.org',
    showOnlyNotHandled: true,
    isShowUnReadMails: true,
    isMergeBetweenBoards: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1143897065',
          name: 'General (This Board)'
        },
        {
          id: '1127892758',
          name: 'Operations'
        },
        {
          id: '1204867316',
          name: 'כוללים בתהליך הצטרפות'
        }
      ]
    }
  },
  '1139066776': {
    //Partnerships+Contacts
    parentBoard: {
      id: '1139066776',
      linkedBoardId: '',
      docsColumn: {id: ''},
      subitemsColumn: {id: ''},
      defaultSubjectColumn: {id: ''},
      contactsNameColumn: {id: 'kollel_english_name'},
      contactsEmailAddreassColumn: {id: 'mirror4'},
      filledFormColumn: {id: 'checkbox'},
      letterReminderColumn: {id: 'check__1'},
      activeColumn: {id: 'active_inactive', activeValue: 'Active'}
    },
    sender: 'office@adoptakollel.org',
    moveOrCopy: {destinationBoards: []}
  },
  '1204867316': {
    //כוללים בתהליך הצטרפות
    parentBoard: {
      id: '1204867316',
      linkedBoardId: '',
      docsColumn: {id: ''},
      subitemsColumn: {id: ''},
      defaultSubjectColumn: {id: ''},
      //פרוספקט
      additionalInfoDocsColumn: {id: '_______'},
      //לפחות 3 תמונות
      continDocsColumn: {id: 'file'},
      //תמונה ראש הכולל
      searchDocsColumn: {id: '__________________6'},
      //תמונה של בנין הכולל
      proofedCommitmentDocsColumn: {id: '___________________'}
    },
    sender: 'office@adoptakollel.org',
    showOnlyNotHandled: true,
    isShowUnReadMails: true,
    isMergeBetweenBoards: true,
    moveOrCopy: {
      destinationBoards: [
        {
          id: '1204867316',
          name: 'כוללים בתהליך הצטרפות (This Board)'
        },
        {
          id: '1127892758',
          name: 'Operations'
        },
        {
          id: '1143897065',
          name: 'General'
        }
      ]
    }
  }
}

export function getConfig(boardId: string) {
  return config[boardId]
}

export function getConfigByParentBoard(parentBoardId: string) {
  const key = Object.keys(config).find(
    key => config[key].parentBoard.id === parentBoardId
  )
  if (key) return config[key]
  else {
  }
}

export function getBoardIdByParentBoard(parentBoardId: string) {
  return Object.keys(config).find(
    key => config[key].parentBoard.id === parentBoardId
  )
}

export function getSender(boardConfig: Config, key: string) {
  if (typeof boardConfig.sender === 'string') return boardConfig.sender
  return boardConfig.sender[key || ''] || ''
}
