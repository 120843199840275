import {useEffect, useState} from 'react'
import mondaySdk from 'monday-sdk-js'
import User from 'src/entities/User'
import {MonRes} from 'src/entities/Monday'
import config from 'src/config'

const monday = mondaySdk()
monday.setApiVersion('2023-10')

const subscribersQuery = (boardId?: string) => `query{
  me {
    name
    id
    photo_small
    email
  }
  boards (ids: ${boardId}) {
    subscribers {
      name
      id
      photo_small
      email
    }
  }
}`

const getSubscribers = async (boardId: string) => {
  if (config.sendMondayLogsUrl.length > 0)
    await fetch(
      `${config.sendMondayLogsUrl}&json=${encodeURIComponent(
        `function:getSubscribers-subscribersQuery,board id:${boardId}`
      )}`
    )
  const res = (await monday.api(subscribersQuery(boardId))) as MonRes
  if (res && res.data.me) {
    const users = [] as User[]
    users.push({
      id: Number(res.data.me.id),
      name: res.data.me.name ?? '',
      displayName: res.data.me.name + ' (You)',
      email: res.data.me.email ?? '',
      photo: res.data.me.photo_small
    })
    if (
      res.data.boards &&
      res.data.boards.length &&
      res.data.boards[0].subscribers
    ) {
      for (let i = 0; i < res.data.boards[0].subscribers.length; i++) {
        const monUser = res.data.boards[0].subscribers[i]
        if (monUser.id === res.data.me.id) continue
        const user = {} as User
        user.id = Number(monUser.id)
        user.name = monUser.name ?? ''
        user.displayName = monUser.name ?? ''
        user.email = monUser.email ?? ''
        user.photo = monUser.photo_small
        users.push(user)
      }
    }
    return users
  }
}

export default function useSubscribers(boardId: string) {
  const [subscribers, setSubscribers] = useState<User[]>()

  useEffect(() => {
    ;(async () => {
      setSubscribers(await getSubscribers(boardId))
    })()
  }, [boardId])

  return {subscribers}
}
