import config from 'src/config'
import {HPDocumentSize} from 'src/entities/HPDocument'

export const validateOrderInHomePgae = async (taskName: string) => {
  try {
    const res = await fetch(
      `${config.validateOrderInHomePgaeUrl}&orderNumber=${taskName}`
    )
    const result = await res.text()
    return result.toLowerCase() === 'true'
  } catch {
    return false
  }
}

export const getDocumentsSize = async (documentIds: string) => {
  try {
    const res = await fetch(
      `${config.documentsSizeUrl}&documentIds=${documentIds}`
    )
    const data = (await res.json()) as HPDocumentSize[]
    return data
  } catch {
    return []
  }
}

export const getDocumentBlob = async (documentId: string) => {
  try {
    const res = await fetch(
      `${config.readAssetsFromHPUrl}&documentId=${documentId}`
    )
    const blob = await res.blob()
    return blob
  } catch {
    return null
  }
}
