import React from 'react'
import {Box, Link, Typography} from '@mui/material'

export default class ErrorBoundary extends React.Component<
  {},
  {hasError: boolean; error: any}
> {
  constructor(props: {}) {
    super(props)
    this.state = {hasError: false, error: undefined}
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, error}
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="h5" sx={{mt: 2, color: '#f50057'}}>
            Oooops! something went wrong...
          </Typography>
          <Typography sx={{mt: 1}}>
            Click <Link href="">here</Link> to reload
          </Typography>
        </Box>
      )
    }

    return this.props.children
  }
}
