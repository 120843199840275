import React, {memo, Suspense} from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Styles from 'src/components/Style'
import MonLoader from 'src/components/MondayUIKit/MonLoader'
import {UsersContextProvider} from 'src/context/Users'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import ErrorBoundary from 'src/components/ErrorBoundary'
import {SaveToWebContextProvider} from 'src/context/SaveToWeb'
import {ReportersContextProvider} from 'src/context/Reporters'
import AuthorizeDropbox from './components/MailBox/AuthorizeDropbox'

const MultiDucumentsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MultiDucumentsPage" */ 'src/components/DocumentsApp/MultiDucumentsPage'
    )
)
const SearchPage = React.lazy(
  () =>
    import(/* webpackChunkName: "SearchPage" */ 'src/components/Search/Search')
)

const DetailsForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DetailsForm" */ 'src/components/Forms/DetailsForms/Form'
    )
)

const MailBoxPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MailBoxPage" */ 'src/components/MailBox/MailBoxPage'
    )
)
const MailBoxBulkPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MailBoxBulkPage" */ 'src/components/MailBox/MailBoxBulkPage'
    )
)

const CorrespondenceBulkPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CorrespondenceBulkPage" */ 'src/components/MailBox/CorrespondenceBulkPage'
    )
)
const CorrespondenceBulkPageMemo = memo(CorrespondenceBulkPage)

const MailBoxBoardPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MailBoxBoardPage" */ 'src/components/MailBox/MailBoxBoardPage'
    )
)

const SendMessagePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SendMessagePage" */ 'src/components/MailBox/SendMessagePage'
    )
)

const PreviewPage = React.lazy(
  () =>
    import(/* webpackChunkName: "PreviewPage" */ 'src/components/PreviewPage')
)

const CorrespondencePreviewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CorrespondencePreviewPage" */ 'src/components/CorrespondencePreviewPage'
    )
)

const App = () => {
  if (window.location.pathname === '/preview')
    return (
      <BrowserRouter>
        <Suspense fallback={<MonLoader backdrop />}>
          <PreviewPage />
        </Suspense>
      </BrowserRouter>
    )

  if (window.location.pathname === '/correspondence-preview')
    return (
      <BrowserRouter>
        <Suspense fallback={<MonLoader backdrop />}>
          <CorrespondencePreviewPage />
        </Suspense>
      </BrowserRouter>
    )

  if (window.location.pathname === '/form')
    return (
      <BrowserRouter>
        <Suspense fallback={<MonLoader backdrop />}>
          <ErrorBoundary>
            <DetailsForm />
          </ErrorBoundary>
        </Suspense>
      </BrowserRouter>
    )

  return (
    <Suspense fallback={<MonLoader />}>
      <Styles>
        <BrowserRouter>
          <ErrorBoundary>
            <UsersContextProvider>
              <ReportersContextProvider>
                <SaveToWebContextProvider>
                  <Routes>
                    <Route
                      path="/auth/callback"
                      element={
                        <Suspense fallback={<MonLoader />}>
                          <AuthorizeDropbox />
                        </Suspense>
                      }
                    />
                    <Route path="/mail-box">
                      <Route
                        path="single"
                        element={
                          <Suspense fallback={<MonLoader />}>
                            <MailBoxPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="bulk"
                        element={
                          <Suspense fallback={<MonLoader />}>
                            <MailBoxBulkPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="correspondence"
                        element={
                          <Suspense fallback={<MonLoader />}>
                            <CorrespondenceBulkPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="aak"
                        element={
                          <Suspense fallback={<MonLoader />}>
                            <CorrespondenceBulkPageMemo AAK />
                          </Suspense>
                        }
                      />
                      <Route
                        path="search"
                        element={
                          <Suspense fallback={<MonLoader />}>
                            <SearchPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="board"
                        element={
                          <Suspense fallback={<MonLoader />}>
                            <MailBoxBoardPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="send"
                        element={
                          <Suspense
                            fallback={
                              <MonLoader size={48} ml={57} mt={'45vh'} />
                            }
                          >
                            <SendMessagePage />
                          </Suspense>
                        }
                      />
                    </Route>
                    <Route path="documents" element={<MultiDucumentsPage />} />
                  </Routes>
                </SaveToWebContextProvider>
              </ReportersContextProvider>
            </UsersContextProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </Styles>
    </Suspense>
  )
}

export default App
